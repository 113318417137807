<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-listitem)="{ data: d }">
      <list-item v-if="d.Id === 'contactname'" :data="d.Data">
        <template #text>
          <input type="text" :placeholder="d.Data.Text" v-model="name" ref="inputname" :class="[{'border-b border-red-500': !name}, 'w-full']"/>
        </template>
        <template #footer>
          <div>
            <span class="text-sm font-normal text-red-500" v-if="!name">Name is required</span>
          </div>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'deliveryaddress'" :data="{...d.Data, ...getStateData('address', 'Addresses')}"></list-item>
      <list-item v-else-if="d.Id === 'paymentmethod'" :data="{...d.Data, ...getStateData('paymentMethod', 'PaymentMethods')}"></list-item>
      <list-item v-else-if="d.Id === 'deliverymethod'" :data="{...d.Data, ...getStateData('deliveryMethod', 'DeliveryMethods')}"></list-item>
      <list-item v-else-if="d.Id === 'subtotalamount'" :data="d.Data">
        <template #righttext>{{config.TotalAmount | currency(user.CurrencyObj) }}</template>
      </list-item>
       <list-item v-else-if="d.Id === 'consolidateamount'" :data="d.Data">
        <template #righttext>{{config.ConsolidateAmount | currency(user.CurrencyObj) }}</template>
      </list-item>
       <list-item v-else-if="d.Id === 'repackingamount'" :data="{...d.Data, Class: !repacking ? 'hidden' : ''}">
        <template #righttext>{{(amounts.AmountRepacking || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'internationalamount'" :data="d.Data">
        <template #righttext>{{(amounts.AmountInternational || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'domesticamount'" :data="d.Data">
        <template #righttext>{{(amounts.AmountDomestic || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'cost1amount'" :data="d.Data">
        <template #righttext>{{(amounts.Cost1Amount || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'cost2amount'" :data="d.Data">
        <template #righttext>{{(amounts.Cost2Amount || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'cost3amount'" :data="d.Data">
        <template #righttext>{{(amounts.Cost3Amount || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'insuranceamount'" :data="{...d.Data, Class: !insurance ? 'hidden' : ''}">
        <template #righttext>{{(insuranceAmount || 0) | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'taxamount'" :data="d.Data">
        <template #righttext>
          {{amounts.TotalTax | currency(user.CurrencyObj) }}
        </template>
        <template #footer>
          <div>
            <span v-if="amounts.AmountBM">BM: {{amounts.AmountBM | currency(user.CurrencyObj) }}</span>
            <span v-if="amounts.AmountBM && amounts.AmountPPN">, </span>
            <span v-if="amounts.AmountPPN">PPN: {{amounts.AmountPPN | currency(user.CurrencyObj) }}</span>
            <span v-if="amounts.AmountPPN && amounts.AmountPPH">, </span>
            <span v-if="amounts.AmountPPH">PPH: {{amounts.AmountPPH | currency(user.CurrencyObj) }}</span>
          </div>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'Note'" :data="d.Data">
        <template #text>
          <textarea :placeholder="d.Data.Text" v-model="note" class="w-full"></textarea>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'totalamount'" :data="d.Data">
        <template #righttitle>{{totalAmount | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'repacking'" :data="d.Data" @select="v => repacking = v"></list-item>
      <list-item v-else-if="d.Id === 'repackingoptions'" :data="{ ...d.Data, Class: !repacking ? 'hidden' : '' }">
        <template #text>
          <b-radio
            v-for="(o) in config.RepackingPrices"
            :key="o.Oid"
            v-model="repackingPrice"
            type="is-primary"
            name="repackingPrice"
            :native-value="o.Oid">
                {{o.Name}}
            </b-radio>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'insurance'" :data="d.Data" @select="v => insurance = v"></list-item>
      <list-item v-else-if="d.Id === 'paybutton'" :data="getPayButtonData(d.Data)" @action-click="pay"></list-item>
      <list-item v-else :data="d.Data" @title-action-click="handleAction"></list-item>
    </template>

    <template #cmslefttype(vaddress-order-liveitem)="{ data: d }">
      <live-item :data="d" v-model="d.Data" @change="handleItemChange" @blur="handleItemBlur"></live-item>
    </template>

    <template #aftercmsleftcomponents>
      <address-modal v-bind="modalProps('address')" @close="closeModal('address')"></address-modal>
      <modal-cms-page v-bind="modalProps('options')" @close="closeModal('options')">
        <template #cmslefttype(vaddress-listitem)="{ data: d }">
          <list-item
            :data="d.Data"
            @click="selectOption(d)"></list-item>
        </template>
      </modal-cms-page>
    </template>
  </page>
</template>
<script>
import AddressModal from './AddressModal'
import ModalCmsPage from '../../base/ModalCMSPage'
import CMSPage from '../../base/CMSPage'
import stripe from '../../../../libs/stripe'
export default {
  extends: CMSPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    LiveItem: () => import('@/components/vaddress/order/LiveItem'),
    ModalCmsPage,
    AddressModal
  },
  data () {
    return {
      name: '',
      note: '',
      repacking: false,
      repackingPrice: null,
      amounts: {},
      insurance: false
    }
  },
  methods: {
    pay () {
      this.$buefy.dialog.confirm({
          message: this.config.SubmitPaymentMessage,
          type: 'is-primary',
          onConfirm: async () => {
            const loading = this.$buefy.loading.open()
            try {
              const paymentMethod = this.$store.state.order.paymentMethod
              if (paymentMethod.IsCreditCardStripe) {
                await this.payStripe()
              }
              const pos = await this.submit()

              this.$store.commit('order/SET_PAYMENT_METHOD', null)
              this.$store.commit('order/SET_ADDRESS', null)
              this.$store.commit('order/SET_DELIVERY_METHOD', null)

              if (paymentMethod.IsTransfer) {
                this.$router.replace('/user/checkout/banktransfer?Oid=' + pos.Oid)
              } else if (paymentMethod.IsVA) {
                this.$router.replace('/user/checkout/va?Oid=' + pos.Oid)
              } else {
                this.$router.replace('/user/orders')
              }
            } catch (err) {
              alert(err.message)
            } finally {
              loading.close()
            }
          }
      })
    },
    payStripe () {
      return stripe.checkout({
        id: this.oids.join('_'),
        v: '1234',
        tokenUrl: '/payment/cc',
        paymentUrl: '/payment/cc_payment',
        data: {
          amount: this.totalAmount
        }
      })
    },
    async submit () {
      return this.$baseApi.post(this.$route.path, {
        Oids: this.oids,
        ContactName: this.name,
        Note: this.note,
        PaymentMethod: this.$store.state.order.paymentMethod.Oid,
        POSUserAddress: this.$store.state.order.address.Oid,
        // DeliveryMethod: this.$store.state.order.deliveryMethod.Oid,
        Repacking: this.repacking,
        AmountRepacking: this.amounts.AmountRepacking,
        AmountInsurance: this.insuranceAmount,
        RepackingPrice: this.repackingPrice,
        ToShippingSubDistrict: this.$store.state.order.address.SubDistrict,
        DomesticShippingPrice: this.amounts.AmountDomestic,
        TaxAmount: this.amounts.TotalTax,
        SubtotalAmount: this.amounts.AmountInternational,
        Details: this.items,
        TotalAmount: this.totalAmount
      })
    },
    handleAction ({ action }) {
      if (action.Id === 'changeaddress') {
        this.openModal('address', {
          url: '/user/checkout/addresses'
        })
      } else if (action.Id === 'changedeliverymethod') {
        this.openModal('options', {
          url: '/user/checkout/deliverymethods'
        })
      } else if (action.Id === 'changepaymentmethod') {
        this.openModal('options', {
          url: '/user/checkout/paymentmethods'
        })
      }
    },
    handleItemChange (k, v, d) {
      if (k === 'ShippingGoodType') {
        this.getInternationalAmount()
        this.$nextTick(() => {
          if (this.canCalculateTax) this.getTaxAmount()
        })
      }
    },
    handleItemBlur (k, v, d) {
      this.$nextTick(() => {
        if (k === 'AmountGoodValue' && this.canCalculateTax) {
          this.getTaxAmount()
        }
      })
    },
    setAmounts (value) {
      this.$set(this, 'amounts', { ...this.amounts, ...value })
    },
    selectOption (d) {
      if (d.Data.Oid) {
        if (this.modals[0].url.indexOf('paymentmethods') !== -1) {
          this.selectPaymentMethod(d.Data)
        } else {
          this.$store.commit('order/SET_DELIVERY_METHOD', d.Data)
        }
        this.closeModal('options')
      }
    },
    selectPaymentMethod (d) {
      this.$store.commit('order/SET_PAYMENT_METHOD', d)
    },
    getStateData (type, ref) {
      const val = this.$store.state.order[type]
      if (val) {
        if (type === 'address') {
          return { ...val, Actions: null }
        }
        return val
      }
      return {}
    },
    getPayButtonData (d) {
      return {
        ...d,
        Actions: [{
          ...d.Actions[0],
          Disabled: this.payButtonDisabled
        }]
      }
    },
    afterRequestCMSData () {
      this.$nextTick(() => {
        if (this.$refs.inputname) this.$refs.inputname.focus()

        if (this.config.DefaultAddress) this.$store.commit('order/SET_ADDRESS', this.config.DefaultAddress)
        if (this.config.DefaultPaymentMethod) this.selectPaymentMethod(this.config.DefaultPaymentMethod)
      })
    },
    async getInternationalAmount (d) {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/international', {
          Warehouse: d.Warehouse,
          QuantityVolume: d.QuantityVolume,
          QuantityWeight: d.QuantityWeight,
          ShippingGoodType: d.ShippingGoodType
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getTaxAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/tax', {
          AmountGoodValue: d.AmountGoodValue,
          ShippingGoodType: d.ShippingGoodType,
          ToShippingSubDistrict: this.$store.state.order.address.SubDistrict
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getRepackingAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/repacking', {
          VirtualAddressRepackingPrice: this.repackingPrice,
          QuantityVolume: d.QuantityVolume,
          VirtualAddressPackage: this.oids[0]
        })
        console.log(res)
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getDomesticAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/domestic', {
          ToShippingSubDistrict: this.$store.state.order.address.SubDistrict,
          QuantityVolume: d.QuantityVolume,
          QuantityWeight: d.QuantityWeight,
          IsSelfPickup: this.$store.state.order.address.IsSelfPickup
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getCost1Amount () {
      const loading = this.$buefy.loading.open()
      try {
        const res = await this.$baseApi.post('cost/cost1', {
          VirtualAddressPackage: this.oids[0]
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getCost2Amount () {
      const loading = this.$buefy.loading.open()
      try {
        const res = await this.$baseApi.post('cost/cost2', {
          VirtualAddressPackage: this.oids[0]
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getCost3Amount () {
      const loading = this.$buefy.loading.open()
      try {
        const res = await this.$baseApi.post('cost/cost3', {
          VirtualAddressPackage: this.oids[0]
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    }
  },
  computed: {
    payButtonDisabled () {
      const {
        address,
        // deliveryMethod,
        paymentMethod
      } = this.$store.state.order
      return !address ||
      !paymentMethod ||
      // !deliveryMethod ||
      !this.totalAmount
    },
    oids () {
        const oids = this.$route.query['Oid[]']
        return Array.isArray(oids) ? oids : [oids]
    },
    insuranceAmount () {
      const d = this.items[0]

      if (!this.insurance) return 0

      const amt = (d.AmountGoodValue * 1) / 100
      console.log(d.AmountGoodValue)
      if (amt < 30000) return 30000
      return amt
    },
    totalAmount () {
      const { AmountInternational, TotalTax, AmountRepacking, AmountDomestic, Cost1Amount, Cost2Amount, Cost3Amount } = this.amounts

      if (!AmountInternational && !TotalTax && !AmountDomestic) return 0

      if (this.repacking && !AmountRepacking) return 0

      return AmountInternational + (AmountRepacking || 0) + TotalTax + AmountDomestic + parseFloat(Cost1Amount) + parseFloat(Cost2Amount) + parseFloat(Cost3Amount) + this.insuranceAmount
    },
    items () {
      return this.cmsLeft.Data
      .filter(d => d.Type === 'vaddress.order.liveitem')
      .map(({ Data: d }) => ({
        Oid: d.Oid,
        Warehouse: d.WarehouseObj ? d.WarehouseObj.Oid : null,
        QuantityVolume: d.Volume,
        QuantityWeight: d.Weight,
        AmountGoodValue: d.AmountGoodValue,
        ShippingGoodType: d.ShippingGoodType,
        AmountBM: this.amounts.AmountBM,
        AmountPPH: this.amounts.AmountPPH,
        AmountPPN: this.amounts.AmountPPN,
        AmountRepacking: this.amounts.AmountRepacking,
        VirtualAddressRepackingPrice: this.repackingPrice,
        TotalTax: this.amounts.TotalTax
      }))
    },
    canCalculateTax () {
      const d = this.items[0]
      return d.AmountGoodValue && d.ShippingGoodType && this.$store.state.order.address.SubDistrict
    }
  },
  watch: {
    repacking (v) {
      if (!v) {
        this.repackingPrice = null
      } else {
        this.repackingPrice = this.config.RepackingPrices[0].Oid
      }
    },
    repackingPrice (v, o) {
      if (v) {
        if (this.items[0].ShippingGoodType) {
          this.getRepackingAmount()
        }
      } else {
        this.amounts.AmountRepacking = 0
      }
    },
    '$store.state.order.address' (v) {
      if (v) {
        this.getDomesticAmount()
        this.getCost1Amount()
        this.getCost2Amount()
        this.getCost3Amount()
        if (this.canCalculateTax) this.getTaxAmount()
      }
    }
  }
}
</script>

<style>

</style>
