<template>
  <modal
    v-bind="modalData"
    @close="close"
  >
    <page :data="pageData" :is-child="true">
      <template #cmslefttype(vaddress-listitem)="{ data: d }">
        <list-item
          :data="d.Data"
          @action-click="({ action }) => handleAction(action, d)"
          @click="select(d)"></list-item>
      </template>
      <template #aftercmsleftcomponents>
        <modal-form-page v-bind="modalProps('form')" @close="closeForm">
        </modal-form-page>
      </template>
    </page>
  </modal>
</template>
<script>
import ModalCMSPage from '../../base/ModalCMSPage'
import ModalFormPage from '../../base/ModalFormPage'
export default {
  extends: ModalCMSPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    ModalFormPage
  },
  methods: {
    select (d) {
      if (d.Data.Oid) {
        this.$store.commit('order/SET_ADDRESS', d.Data)
        this.close()
      }
    },
    handleAction (a, d) {
      if (a.Id === 'delete') {

      } else {
        this.edit(d.Data)
      }
    },
    edit (d) {
      const url = '/user/addresses' + (d.Oid ? `/edit?Oid=${d.Oid}` : '/create')
      this.openModal('form', { url })
    },
    closeForm () {
      this.closeModal('form')
      this.init()
    }
  }
}
</script>

<style>

</style>
